// src/data/apartments.js
const apartments = [
  {
    status: "not available",
    apartment: "101",
    block: "A",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 78,
    balconyArea: 30,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    airconditioning: true,
    privateroofterrace: false,
    cinema: false,
    bbqarea: false,
    price: 330000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_101_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_101_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_101_03.webp"),
      require("@/assets/img/plans/apartments A 101.png"),
    ],
  },
  {
    status: "available",
    apartment: "102",
    block: "A",
    bedrooms: 1,
    bathrooms: 1,
    flatArea: 62,
    balconyArea: 11,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 240000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_102_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_102_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_102_03.webp"),
      require("@/assets/img/apts_for_web_V1/A_102_04.webp"),
      require("@/assets/img/plans/apartments A 102.png"),
    ],
  },
  {
    status: "not available",
    apartment: "103",
    block: "A",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 87,
    balconyArea: 30,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 345000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_103_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_103_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_103_03.webp"),
      require("@/assets/img/plans/apartments A 103.png"),
    ],
  },
  {
    status: "available",
    apartment: "201",
    block: "A",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 78,
    balconyArea: 30,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 345000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_101_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_101_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_101_03.webp"),
      require("@/assets/img/plans/apartments A 201.png"),
    ],
  },
  {
    status: "available",
    apartment: "202",
    block: "A",
    bedrooms: 1,
    bathrooms: 1,
    flatArea: 62,
    balconyArea: 11,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 255000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_102_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_102_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_102_03.webp"),
      require("@/assets/img/apts_for_web_V1/A_102_04.webp"),
      require("@/assets/img/plans/apartments A 202.png"),
    ],
  },
  {
    status: "available",
    apartment: "203",
    block: "A",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 87,
    balconyArea: 30,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 380000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_103_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_103_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_103_03.webp"),
      require("@/assets/img/plans/apartments A 203.png"),
    ],
  },
  {
    status: "available",
    apartment: "301",
    block: "A",
    bedrooms: 3,
    bathrooms: 3,
    flatArea: 168,
    balconyArea: 146,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 910000,
    images: [
      require("@/assets/img/apts_for_web_V1/A_301_01.webp"),
      require("@/assets/img/apts_for_web_V1/A_301_02.webp"),
      require("@/assets/img/apts_for_web_V1/A_301_03.webp"),
      require("@/assets/img/apts_for_web_V1/A_301_04.webp"),
      require("@/assets/img/apts_for_web_V1/A_301_05.webp"),
      require("@/assets/img/plans/penthouse A.png"),
    ],
  },
  {
    status: "not available",
    apartment: "101",
    block: "B1",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 78,
    balconyArea: 29,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 330000,
    images: [
      require("@/assets/img/complex/01.png"),
      require("@/assets/img/complex/02.png"),
      require("@/assets/img/complex/03.png"),
      require("@/assets/img/complex/04.png"),
      require("@/assets/img/plans/apartments B1 101.png"),
    ],
  },
  {
    status: "not available",
    apartment: "102",
    block: "B1",
    bedrooms: 1,
    bathrooms: 1,
    flatArea: 60,
    balconyArea: 11,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 240000,
    images: [
      require("@/assets/img/complex/01.png"),
      require("@/assets/img/complex/02.png"),
      require("@/assets/img/complex/03.png"),
      require("@/assets/img/complex/04.png"),
      require("@/assets/img/plans/apartments B1 102.png"),
    ],
  },
  {
    status: "not available",
    apartment: "103",
    block: "B1",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 102,
    balconyArea: 25,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 360000,
    images: [
      require("@/assets/img/complex/01.png"),
      require("@/assets/img/complex/02.png"),
      require("@/assets/img/complex/03.png"),
      require("@/assets/img/complex/04.png"),
      require("@/assets/img/plans/apartments B1 103.png"),
    ],
  },
  {
    status: "not available",
    apartment: "201",
    block: "B1",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 78,
    balconyArea: 29,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 345000,
    images: [
      require("@/assets/img/complex/01.png"),
      require("@/assets/img/complex/02.png"),
      require("@/assets/img/complex/03.png"),
      require("@/assets/img/complex/04.png"),
      require("@/assets/img/plans/apartments B1 201.png"),
    ],
  },
  {
    status: "available",
    apartment: "202",
    block: "B1",
    bedrooms: 1,
    bathrooms: 1,
    flatArea: 60,
    balconyArea: 11,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 250000,
    images: [
      require("@/assets/img/apts_for_web_V2/1B_1 - 1.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 2.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 3.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 4.webp"),
      require("@/assets/img/plans/apartments B1 202.png"),
    ],
  },
  {
    status: "not available",
    apartment: "203",
    block: "B1",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 102,
    balconyArea: 25,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 400000,
    images: [
      require("@/assets/img/complex/01.png"),
      require("@/assets/img/complex/02.png"),
      require("@/assets/img/complex/03.png"),
      require("@/assets/img/complex/04.png"),
      require("@/assets/img/plans/apartments B1 203.png"),
    ],
  },
  {
    status: "available",
    apartment: "301",
    block: "B1",
    bedrooms: 3,
    bathrooms: 2,
    flatArea: 150,
    balconyArea: 172,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 945000,
    images: [
      require("@/assets/img/apts_for_web_V2/PH - 1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 2.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 3-1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 3.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 4 1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 5.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 6-1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 6.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 7.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 8.webp"),
      require("@/assets/img/plans/penthouse B1.png"),
    ],
  },
  {
    status: "available",
    apartment: "101",
    block: "B2",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 78,
    balconyArea: 29,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 330000,
    images: [
      require("@/assets/img/apts_for_web_V2/2B_2 - 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 2.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 3-2.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 4-2.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 5.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 6.webp"),
      require("@/assets/img/plans/apartments B2 101.png"),
    ],
    promoimg: require("@/assets/img/apts_for_web_V2/b2_101_promo.png"),
    promoprice: 310000,
  },
  {
    status: "available",
    apartment: "102",
    block: "B2",
    bedrooms: 1,
    bathrooms: 1,
    flatArea: 60,
    balconyArea: 11,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 240000,
    images: [
      require("@/assets/img/apts_for_web_V2/1B_1 - 1.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 2.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 3.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 4.webp"),
      require("@/assets/img/plans/apartments B2 102.png"),
    ],
  },
  {
    status: "available",
    apartment: "103",
    block: "B2",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 102,
    balconyArea: 25,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 360000,
    images: [
      require("@/assets/img/apts_for_web_V2/2B_1 - 1 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 2 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 3 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 4 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 5 1.webp"),
      require("@/assets/img/plans/apartments B2 103.png"),
    ],
  },
  {
    status: "available",
    apartment: "201",
    block: "B2",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 78,
    balconyArea: 29,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 345000,
    images: [
      require("@/assets/img/apts_for_web_V2/2B_2 - 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 2.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 3-2.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 4-2.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 5.webp"),
      require("@/assets/img/apts_for_web_V2/2B_2 - 6.webp"),
      require("@/assets/img/plans/apartments B2 201.png"),
    ],
  },
  {
    status: "available",
    apartment: "202",
    block: "B2",
    bedrooms: 1,
    bathrooms: 1,
    flatArea: 60,
    balconyArea: 11,
    seaView: false,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 250000,
    images: [
      require("@/assets/img/apts_for_web_V2/1B_1 - 1.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 2.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 3.webp"),
      require("@/assets/img/apts_for_web_V2/1B_1 - 4.webp"),
      require("@/assets/img/plans/apartments B2 202.png"),
    ],
  },
  {
    status: "available",
    apartment: "203",
    block: "B2",
    bedrooms: 2,
    bathrooms: 2,
    flatArea: 102,
    balconyArea: 25,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 400000,
    images: [
      require("@/assets/img/apts_for_web_V2/2B_1 - 1 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 2 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 3 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 4 1.webp"),
      require("@/assets/img/apts_for_web_V2/2B_1 - 5 1.webp"),
      require("@/assets/img/plans/apartments B2 203.png"),
    ],
  },
  {
    status: "available",
    apartment: "301",
    block: "B2",
    bedrooms: 3,
    bathrooms: 2,
    flatArea: 150,
    balconyArea: 172,
    seaView: true,
    elevator: true,
    parking: true,
    heatedFloor: true,
    storageRoom: true,
    price: 960000,
    images: [
      require("@/assets/img/apts_for_web_V2/PH - 1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 2.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 3-1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 3.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 4 1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 5.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 6-1.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 6.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 7.webp"),
      require("@/assets/img/apts_for_web_V2/PH - 8.webp"),
      require("@/assets/img/plans/penthouse B2.png"),
    ],
  },
];

export default apartments;
