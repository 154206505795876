import { createRouter, createWebHistory } from "vue-router"; // Import createWebHistory
import HomeView from "../views/HomeView.vue";
import ContactsView from "../views/Contacts.vue";
import AboutCyprusView from "../views/AboutCyprus.vue";
import PrivacyPolicyView from "../views/PrivacyPolicy.vue";
import MiraGardenView from "@/views/MiraGarden.vue";
import MiraApartmentsView from "@/views/MiraApartments.vue";
import FlatView from "@/views/FlatView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: ContactsView,
  },
  {
    path: "/about-cypr",
    name: "About Cyprus",
    component: AboutCyprusView,
  },
  {
    path: "/privacy",
    name: "Privacy Policy",
    component: PrivacyPolicyView,
  },
  {
    path: "/miragarden",
    name: "Mira Garden",
    component: MiraGardenView,
  },
  {
    path: "/mira_apartments",
    name: "Mira Apartments",
    component: MiraApartmentsView,
  },
  {
    // Define the dynamic route to match "/sale<block><apartment>"
    path: "/sale:block([a-zA-Z0-9]+)a:apartment(\\d+)",
    component: FlatView,
    props: true, // This will pass route parameters as props to your component
  },
];

const router = createRouter({
  history: createWebHistory(), // Use createWebHistory for history mode
  routes,
});

export default router;
